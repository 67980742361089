import { useState, useCallback } from "react";
import { Blurhash } from "react-blurhash";

export const BlurHashLoader = ({ imageUrl, blurHash, className, imageAlt }) => {
    const [opacity, setOpacity] = useState(0);

    const handleImageLoaded = () => {
        setOpacity(1);
    };

    const handleImageError = () => {
        console.log("This image has failed to Load");
    };

    return (
        <div
            className={className}
            style={{
                position: "relative",
                width: "100%",
                height: "100%",
                borderRadius: "0.75rem",
            }}
        >
            <div
                style={{ position: "absolute", width: "100%", height: "100%" }}
            >
                <Blurhash
                    hash={blurHash}
                    width={"100%"}
                    height={"100%"}
                    resolutionX={32}
                    resolutionY={32}
                    punch={1}
                />
            </div>
            <div
                style={{
                    position: "absolute",
                    top: 0,
                    width: "100%",
                    height: "100%",
                }}
            >
                <img
                    style={{
                        width: "100%",
                        height: "100%",
                        opacity: opacity,
                        transition: "0.3s all ease-in-out",
                        objectFit: "cover", // or "contain" based on your needs
                        borderRadius: "0.75rem", // adding this here if you need border radius
                    }}
                    src={imageUrl}
                    alt={imageAlt}
                    loading="lazy"
                    onLoad={handleImageLoaded}
                    onError={handleImageError}
                />
            </div>
        </div>
    );
};
